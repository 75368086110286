exports.components = {
  "component---src-pages-card-js": () => import("./../../../src/pages/card.js" /* webpackChunkName: "component---src-pages-card-js" */),
  "component---src-pages-cards-add-js": () => import("./../../../src/pages/cards/add.js" /* webpackChunkName: "component---src-pages-cards-add-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

